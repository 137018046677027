        <template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row>
                <v-col class="text-right mt-n5" style="font-size:20px; color:#555555;">전체 : {{ this.$options.filters.number(totalCount) }} 건</v-col>
            </v-row>

            <v-card flat class="mt-4">
                <v-card-text class="pa-0">
                    <v-simple-table class="border-table">
                        <template v-slot:default>
                            <thead>
                            <tr style="background-color:#F4F6F8; height:39px; color:#333333; font-weight:600;">
                                <th colspan="2" class="text-center text-h6 border-th-bottom" style="min-width:220px;">연락처</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom" style="min-width:200px;">등록일시</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom" style="min-width:200px;">등록자</th>
                                <th rowspan="2" class="text-center text-h6 border-th-bottom">메모</th>
                            </tr>
                            <tr style="background-color:#F4F6F8; height:39px; color:#333333; font-weight:600;">
                                <th class="text-center text-h6">전화번호</th>
                                <th class="text-center text-h6 border-th-right">이름(표시명)</th>
                            </tr>
                            </thead>
                            <tbody style="color:#555555;">
                            <template v-for="(groupItem, groupIdx) in groupList">
                                <tr style="height:68px; cursor: pointer;" :key="'group_' + groupIdx" @click="popupCustMemo(groupItem)">
                                    <td class="text-center" style="font-size:20px; font-weight:600;">
                                        <v-icon color="grey" v-if="groupItem.cust_label_type == '0'">mdi-face</v-icon>
                                        <v-icon color="red" v-if="groupItem.cust_label_type == '1'">mdi-face</v-icon>
                                        <v-icon color="ornage" v-if="groupItem.cust_label_type == '2'">mdi-face</v-icon>
                                        <v-icon color="blue" v-if="groupItem.cust_label_type == '3'">mdi-face</v-icon>
                                        <v-icon color="grey darken-3" v-if="groupItem.cust_label_type == '4'">mdi-face</v-icon>
                                        {{ groupItem.cust_mdn | phoneNum }}
                                    </td>
                                    <td class="text-center" style="font-size:20px;">
                                        <template v-if="groupItem.cust_name && groupItem.cust_name.length != 0">{{ groupItem.cust_name }}</template>
                                        <template v-else>
                                            <v-btn outlined color="primary" @click.stop="popupAddrBook(groupItem.cust_mdn)">연락처 등록</v-btn>
                                        </template>
                                    </td>
                                    <td class="text-center" style="font-size:18px;">{{ groupItem.reg_dt | dateMin }}</td>
                                    <td class="text-center" style="font-size:18px;">{{ groupItem.writer_name }}</td>
                                    <td class="text-left" style="font-size:18px;">{{ groupItem.memo_text }}
                                        <span style="font-size:18px;" v-if="groupItem.memo_count > 1">({{ groupItem.memo_count }})</span>
                                    </td>
                                </tr>
                            </template>
                            <tr style="height:69px;" v-if="!groupList || groupList.length == 0">
                                <td class="text-center" style="font-size:20px;" colspan="5">조회된 결과가 없습니다</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <v-row class="mt-4">
                <v-col cols="3" class="text-left">
                    <v-btn v-on:click="downloadExcel" :loading="excelLoading">DOWNLOAD</v-btn>
                </v-col>
                <v-col cols="9" class="text-right">
                    <tile-paging :pageNo="searchParam.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapState} from 'vuex'
import svcHistApi from '@/api/svc_hist'

export default {
    name: 'svc_hist_callback_list',
    components: {
        'TilePaging': () => import('@/components/common/tile_paging.vue'),
        'TileSearchDate': () => import('@/components/common/tile_search_date.vue')
    },
    data: () => ({
        searchParam: {
            page_no: 1,
            page_size: 25
        },
        excelLoading: false,
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_hist_cust_memo_group')

        let lastMenuId = this.$store.getters['lastMenuId']
        if (lastMenuId == 'svc_hist_cust_memo_group' && this.curSearchParam) {
            Object.assign(this.search, this.curSearchParam)
        }

        this.doSearch(1)
    },
    computed: {
        ...mapState('svc_hist_cust_memo', {
            curSearchParam: state => state.groupSearchParam,
            totalCount: state => state.groupTotalCount,
            groupList: state => state.groupList,
        }),
        totalPageCount() {
            if (this.totalCount == 0) {
                return 0
            }
            let pageSize = (this.searchParam && this.searchParam.page_size) ? this.searchParam.page_size : 25
            let cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        },
        isLoaded() {
            if (this.groupList) {
                return true
            }
            return false
        }
    },
    methods: {
        doSearch(no) {
            if (no) {
                this.searchParam.page_no = no
            }

            this.$store.dispatch('svc_hist_cust_memo/getGroupList', {
                searchParam: this.searchParam,
                scb: (stat_list, total_count, va) => {
                },
                fcb: (err, va) => {
                },
                va: this
            })
        },
        downloadExcel() {
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '메모 목록을 엑셀 파일로 다운로드하시겠습니까?',
                va: va,
                okCb: function (va) {
                    va.excelLoading = true
                    svcHistApi.downloadMemoExcel({},
                        (va) => {
                            va.excelLoading = false
                            // SUCC
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드 되었습니다',})
                        }, (err, va) => {
                            va.excelLoading = false
                            // FAIL
                            va.$store.dispatch('openAlert', {message: '엑셀 파일이 다운로드를 실패 했습니다',})
                        }, va
                    )
                }
            })
        },
        popupAddrBook(custMdn) {
            this.$store.dispatch('openAddrBook',
                {
                    'addrBook': {
                        'cust_mdn': custMdn,
                        'pageMode': 'add_with_mdn'
                    },
                    'okCb': (va) => {
                        va.doSearch()
                    },
                    'va': this
                }
            )
        },
        popupCustMemo(item) {
            this.$emit('open-memo', {'cust_seqno': item.cust_seqno, 'cust_mdn': item.cust_mdn})
        },
        refresh() {
            this.doSearch()
        }
    }
}
</script>

<style scoped>
.bd-thin-between {
    border-left: thin solid rgba(0, 0, 0, 0.05);
    border-bottom: thin solid rgba(0, 0, 0, 0.05);
}

.bd-thin-bottom {
    border-bottom: thin solid rgba(0, 0, 0, 0.05);
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.05);
}
</style>